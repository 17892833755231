import "./App.css";
import { lazy, Suspense, useEffect } from "react";
import * as React from "react";
import { LinearProgress } from "@mui/material";

const Index = lazy(() => import("./pages/Index"));
const Footer = lazy(() => import("./components/Footer"));

function App() {
  useEffect(() => {
    if (document) {
      const stylesheet = document.createElement("link");
      stylesheet.rel = "stylesheet";
      stylesheet.href =
        "https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css";

      document.head.appendChild(stylesheet);
    }
  }, []);
  return (
    <div className="App">
      {}
      <Suspense
        fallback={
          <div className="fallback">
            <LinearProgress />
          </div>
        }
      >
        <Index />
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
